<template>
  <div>
    <v-dialog v-model="exibeModalProduto" max-width="1200px" persistent>
      <v-card>
        <v-card-title>
          <div class="card-title">
            {{ $tc('label.selecionar_produto', 2) }}
          </div>
        </v-card-title>
        <v-card-text>
          <v-row v-if="exibeModalProduto">
            <v-col cols="4">
              <v-text-field
                :label="$tc('label.categoria', 1)"
                v-model="codNomeCategoria"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :label="$tc('label.familia', 1)"
                v-model="codNomeFamilia"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="start">
            <v-col cols="12" sm="12" class="mx-3">
              <v-text-field
                v-model="searchQuery"
                append-icon="search"
                :label="$t('label.pesquisar_modal_selecao_produto')"
                single-line
                clearable
                hide-details>
              </v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="listaProdutos"
            v-model="selected"
            :key="tableModalProdutosKey"
            :options.sync="pagination"
            :server-items-length="totalPage"
            :no-data-text="$t('label.tabela_sem_conteudo')"
            :footer-props="{
              itemsPerPageOptions: [10, 25, 50],
            }">
            <template v-slot:item.kit="{ item }">
              <v-tooltip bottom v-if="item.indKit">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" v-on="on"  @click="visualizarProdutosKit(item)">
                    <v-icon>visibility</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('label.kit') }}</span>
              </v-tooltip>
                <span v-else >{{ '-' }}</span>
          </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secundary"
            @click="close">{{ $t('label.voltar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <modal-produtos-kit
      ref="modalProdutosKit">
    </modal-produtos-kit>
  </div>
</template>
<script>
import { buscarProdutosLinhaProduto } from '@/produto/common/resources/planejamento/planejamento-acao-cadastro';
import ModalProdutosKit from '@/produto/spa/planejamento-acao/form-verba-variavel/PlanejamentoAcaoVerbaVariavelProdutosKit';

export default {
  name: 'ApuracaoAcaoVerbaVariavelProdutoModalSelecao',
  components: {
    ModalProdutosKit,
  },
  props: {
    novaAcao: Boolean,
    somenteLeitura: Boolean,
    acao: Object,
    categoriaSelecionada: Object,
    familiaSelecionada: Object,
    idsProdutos: Array,
    linhaProduto: Object,
  },
  computed: {
    codNomeCategoria() {
      if (this.linhaProduto && this.linhaProduto.codNomeCategoria) {
        return this.linhaProduto.codNomeCategoria;
      }
      return '';
    },
    codNomeFamilia() {
      if (this.linhaProduto && this.linhaProduto.codNomeFamilia) {
        return this.linhaProduto.codNomeFamilia;
      }
      return '';
    },
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),

      exibeModalProduto: false,
      searchQuery: null,
      listaProdutos: [],
      listaProdutosFiltrada: [],
      linhaProdutoSelecionada: {},
      tableModalProdutosKey: 0,
      totalPage: 0,
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      timeout: 0,
      esperar: false,
      tamanhoTotalProdutos: 0,
      indexLinhaProdutoSelecionada: -1,
      selected: [],
      produtosSelecionados: null,
      headers: [
        { text: this.$tc('label.categoria', 1), value: 'codNomeCategoria', sortable: false },
        { text: this.$tc('label.familia', 1), value: 'codNomeFamilia', sortable: false },
        { text: this.$tc('label.ibm', 1), value: 'desIbm', sortable: false },
        { text: this.$tc('label.cod_produto_com_digito', 1), value: 'codProdutoComDigito', sortable: false },
        { text: this.$tc('label.produto', 1), value: 'codNomeProduto', sortable: false },
        { text: this.$tc('label.kit', 1), value: 'kit', sortable: false },
        { text: this.$tc('label.referencia', 1), value: 'desModelo', sortable: false },
      ],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredLista: (value) => (!!value && value.length > 0) || this.$t('message.campo_obrigatorio'),
        valorPositivo: (value) => (!!value && value > 0) || this.$t('errors.valor.negativo'),
        valorMaiorQuePendente: (value) => (!!value && value <= this.valorOriginalPendente) || this.$t('errors.valor.maior_que_pendente'),
        validDate: (value) => (!value || (value.length === 10 && this.moment(value, 'DD-MM-YYYY').isValid())) || this.$t('message.data_invalida'),
      },
    };
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
    searchQuery() {
      this.pagination.page = 1;
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar();
      }, 500);
    },
  },
  methods: {
    open(produtosSelecionados) {
      this.produtosSelecionados = produtosSelecionados;
      this.pagination.page = 1;
      this.filtrar();
      this.exibeModalProduto = true;
    },
    close() {
      this.exibeModalProduto = false;
      this.resetaModal();
      this.$emit('ModalSelecaoProduto__ResetaSelecao');
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscarProdutos();
      }, 1000);
    },
    buscarProdutos() {
      const params = {
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
        asc: this.pagination.sortDesc ? !this.pagination.sortDesc[0] : true,
        colunaOrdenacao: this.pagination.sortBy ? this.pagination.sortBy[0] : 'cod_nome_produto',
      };
      if (this.searchQuery && this.searchQuery.length) {
        params.filtro = this.searchQuery;
      }
      if (this.linhaProduto.idFamilia && this.linhaProduto.idFamilia !== 0) {
        params.id_familia = this.linhaProduto.idFamilia;
      }
      if (this.linhaProduto.idCategoria && !params.id_familia) {
        params.id_categoria = this.linhaProduto.idCategoria;
      }
      if (this.linhaProduto.idsProdutos && this.linhaProduto.idsProdutos.length) {
        params.id_linha_produto = this.linhaProduto.id;
      }

      if (this.linhaProduto.idGrupoFornecedor) {
        params.id_grupo_fornecedor = this.linhaProduto.idGrupoFornecedor;
      }

      return buscarProdutosLinhaProduto(params, this.$resource)
        .then((res) => {
          this.totalPage = res.data.quantidadeRegistrosPagina;
          this.listaProdutos = res.data.resposta;
          this.tableModalProdutosKey += 1;
          this.pararEsperar();
        });
    },
    setarProdutosSelecionados(produtosSelecionados) {
      this.resetaModal();
      this.selected = produtosSelecionados;
    },
    setarLinhaProdutoSelecionada(linhaProdutoSelecionada) {
      this.resetaModal();
      this.linhaProdutoSelecionada = linhaProdutoSelecionada;
    },
    resetaModal() {
      this.selected = [];
      this.listaProdutos = [];
      this.listaProdutosFiltrada = [];
      this.indexLinhaProdutoSelecionada = -1;
      this.linhaProdutoSelecionada = {};
      this.searchQuery = null;
      this.pagination.page = 1;
      this.pagination.itemsPerPage = 10;
      this.tamanhoTotalProdutos = 0;
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    visualizarProdutosKit(item) {
      this.planejamentoAcaoResource.buscarProdutoskit({ idExterno: item.idExterno })
        .then((res) => {
          if (res.body) {
            setTimeout(() => this.$refs.modalProdutosKit.open(res.body), 2E2);
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
  },
};
</script>
<style>
.ApuracaoAcaoDetalhesPagamentoModal__valorPendente-negativo,
.ApuracaoAcaoDetalhesPagamentoModal__valorPendente-negativo input.v-money {
  color: #ff0000ba;
}
</style>
